import React from "react";
import classnames from "classnames";

import "./Nav.scss";

class Nav extends React.Component {
  state = {
    showMenu: false,
  };

  handleClickMenu = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };

  handleCloseMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    const { showMenu } = this.state;
    return (
      <div className="nav">
        <div className="nav__header">
          <div>
            <a href="/" className="nav__title">
              yoob.space
            </a>
            <div className="nav__subtitle">A space for yoobs!</div>
          </div>
          <button
            className={classnames("nav__hamburgerMenu", {
              "nav__hamburgerMenu--open": showMenu,
            })}
            onClick={this.handleClickMenu}
          >
            <div className="nav__bun--top">
              <div className="nav__sesame">· · ·</div>
              <div className="nav__sesame">· · · ·</div>
            </div>
            <div className="nav__tomato"></div>
            <div className="nav__lettuce"></div>
            <div className="nav__patty"></div>
            <div className="nav__bun--bottom"></div>
          </button>
        </div>
        {showMenu && <NavMenu />}
      </div>
    );
  }
}

function NavMenu() {
  return (
    <div className="nav__menu">
      <div className="nav__menuTitle">MENU</div>
      <div className="nav__featured">
        Today's special: <a href="/sleepy">Pics o’ Sleepy</a>
      </div>
      <ul>
        <li>
          <a href="/books">Yoob reviews</a>
        </li>
        <li>
          <a href="/sleepy">Sleepy</a>
        </li>
      </ul>
      <a href="/">Go home</a>
    </div>
  );
}

export default Nav;
