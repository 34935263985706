import React from "react";

import "./Books.scss";

export default function Books() {
  return (
    <div className="books">
      <h1>recently read:</h1>
      <ul>
        <li className="books_entry">
          <i>Woman, Eating</i> by Claire Kohda
          <p className="books__review">
            Depresso vampire protagonist coming-of-age story. I liked the casual
            voice and how easy it was to read. Pacing was a slog at times but
            felt like I was reading about a real young woman and her thoughts.
            Love line was WHACK, but overall funny subversions of the vampire
            genre.
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/0488b865-fae0-4a2c-a554-0ab8d9eeeb37/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>The Cartographers</i> by Peng Shepherd
          <p className="books__review">
            Ah, I felt this one was a real shame. The writing was fine and
            pleasant to read, but the main mystery of the plot relies on a
            "fantasy" element (I'd say this book is soft-fantasy) that is really
            contradictory. Not enough internal logic to support itself IMHO!
            Destroyed my suspension of disbelief at what was meant to be (?) the
            climax of the novel. I would say that my disappointment at the plot
            holes was big enough to never recommend this book to anyone lol.
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/437716ca-4424-42da-ab82-4047dc3df3e6/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>Kim Jiyoung, Born 1982</i> by Cho Nam-joo
          <p className="books__review">
            Subject matter felt very familiar bc of shared Korean cultural
            background, nothing really surprising. Examines Korean misogyny in a
            straightforward way, which just made me feel angry while I read it.
            Would wholeheartedly recommend it to men.
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/89aafc51-1b2d-42c6-93de-3038e93820fe/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>The Vanishing Half</i> by Brit Bennett
          <p className="books__review">
            I loved this! Another one I could not put down. Super interesting
            exploration of "whiteness" as a social construct and generational
            trauma while also being a mysterious, thrilling, entertaining,
            heart-wrenching page-turner. It's historical fiction and the
            timeline spans ~50 years, but it's really about one family. You
            follow many (most) of the characters' perspectives, and I enjoyed
            all of them. IMO the characters were all really fleshed out and I
            was impressed with the range of it all! BUT as a content warning
            themes/topics of violence and sexual violence are included. It isn't
            like extremely graphic depictions, but it is also central to the
            narrative.
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/b6191c39-c271-482b-97e1-8556bb3f4b62/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>Piranesi</i> by Susanna Clarke
          <p className="books__review">
            I read it on a plane! So good! Beginning can be a bit slow bc you're
            (literally) getting your bearings of a fantasy world. Overall highly
            recommend, but I'd say if you're the kind of reader who tries to
            keep up with all the nitty gritty details you really don't need to.
            Just let the descriptions wash over you and push through!
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/a4f97770-d661-4687-944e-89e02077190d/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>On the Origin of Species and Other Stories</i> by Kim Bo-young
          <p className="books__review">
            Fun collection of sci fi short stories, highly recommend
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/8f228b96-a3ec-41e9-9e60-557d37ea1607/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>Where the Crawdads Sing</i> by Delia Owens
          <p className="books__review">
            I really liked this one! Especially the first half where you really
            see the main character grow up and learn to survive. It is also so
            so sad tho :( . The writing was really beautiful, especially all of
            the natural imagery of the marsh. I will say the tail-end of the
            murder mystery plot felt a little "meh" to me, but it wasn't a bad
            ending. Also, this one is definitely a trigger warning book for me.
            There are multiple, quite graphic depictions of sexual violence,
            which I found difficult to get through.
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/458f6a9f-1d9b-4038-80cf-34694ea3349d/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
        <li className="books_entry">
          <i>Summer of the Big Bachi</i> by Naomi Hirahara
          <p className="books__review">
            First of the Mas Arai mysteries. It was oooookay.
          </p>
          <div className="books_cw">
            Content warnings: Death, graphic depiction of atomic bombing of
            Hiroshima
          </div>
        </li>
        <li className="books_entry">
          <i>Shadow and Bone Trilogy</i> by Leigh Bardugo
          <p className="books__review">
            Fun read! Good YA fantasy, 2nd book felt like a slog to get through
            at times, but the 1st and 3rd felt like a blaze!
          </p>
          <a
            className="books_cw"
            href="https://app.thestorygraph.com/books/035f611f-bca0-49e6-b6e1-312fd160c8ec/content_warnings"
          >
            Content warnings from storygraph
          </a>
        </li>
      </ul>
      <h1>want to read:</h1>
      <ul>
        <li>
          <i>Before the coffee gets cold</i> by Toshikazu Kawaguchi
        </li>
        <li>
          <i>The Employees</i> by Olga Ravn
        </li>
        <li>
          <i>Portrait of a Thief</i> by Grace D. Li
        </li>
        <li>
          <i>If I Had Your Face</i> by Frances Cha
        </li>
      </ul>
    </div>
  );
}
