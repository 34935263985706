import React from "react";

import Gallery from "./Gallery";

import "./SleepyGallery.scss";

export default function SleepyGallery() {
  return (
    <div className="sleepyGallery">
      <div className="sleepyGallery__header">pics o’ sleepy</div>
      <Gallery
        src={[
          "sleepy1.jpg",
          "sleepy2.jpg",
          "sleepy3.jpg",
          "sleepy4.jpg",
          "sleepy5.jpg",
        ]}
      />
    </div>
  );
}
