import React from "react";

import "./Home.scss";

export default function Home() {
  return (
    <div className="home">
      <img className="home__room" src="/home-page-room.png" alt="" />
      <a className="home__sleepy home__button" href="/sleepy">
        <span>sleepy</span>
      </a>
      <a className="home__books home__button" href="/books">
        <span>reading</span>
      </a>
    </div>
  );
}
