import React from "react";

import "./Gallery.scss";

class Gallery extends React.Component {
  state = {
    index: 0,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 39) this.handleNextImage();
    if (e.keyCode === 37) this.handlePreviousImage();
  };

  handlePreviousImage = () => {
    const { index } = this.state;
    if (index > 0) this.setState({ index: index - 1 });
  };

  handleNextImage = () => {
    const { index } = this.state;
    const lastIndex = this.props.src?.length - 1;
    if (index < lastIndex) this.setState({ index: index + 1 });
  };

  render() {
    const { src } = this.props;
    const { index } = this.state;

    const lastIndex = src?.length - 1;
    const isPrevDisabled = index === 0;
    const isNextDisabled = index >= lastIndex;

    return (
      <div className="gallery">
        <img src={src[index]} alt="" />

        <div className="gallery__nav">
          Navigate using left ← and right → keys
          <div className="gallery__navButtons">
            <button
              disabled={isPrevDisabled}
              onClick={this.handlePreviousImage}
            >
              Prev
            </button>
            <button disabled={isNextDisabled} onClick={this.handleNextImage}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
