import React from "react";
import { Switch, Route } from "react-router-dom";

import Books from "./components/Books";
import Comix from "./components/Comix";
import Home from "./components/Home";
import Nav from "./components/Nav";
import SleepyGallery from "./components/SleepyGallery";

import "./App.scss";

function App() {
  return (
    <div className="app">
      <Nav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/books" component={Books} />
        <Route exact path="/comix" component={Comix} />
        <Route exact path="/sleepy" component={SleepyGallery} />
      </Switch>
    </div>
  );
}

export default App;
